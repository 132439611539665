import Vue from "vue";
import objectAssign from "element-ui/src/utils/merge";
import { getPropByPath } from "element-ui/src/utils/util";
import debounce from "throttle-debounce/debounce";
// import { t } from 'element-ui/src/locale';
import { DATE_FORMAT } from "@/config/rule";
import {
  datePicker,
  monthPicker,
  yearPicker,
} from "@/lib/element-base-options";
import { replaceArray, eventPermissionCheck } from "@/lib/util";
import { CommonAPI } from "@/services/api";

let columnIdSeed = 1;

const defaults = {
  default: {
    order: "",
  },
  selection: {
    width: 60,
    minWidth: 60,
    realWidth: 60,
    order: "",
    className: "el-table-column--selection",
  },
  expand: {
    width: 45,
    minWidth: 45,
    realWidth: 45,
    order: "",
  },
  index: {
    width: 45,
    minWidth: 45,
    realWidth: 45,
    order: "",
  },
  deletion: {
    width: 70,
    minWidth: 70,
    realWidth: 70,
    className: "el-table-column--deletion el-table-column--selection",
  },
  draftLink: {},
  link: {},
  text: {},
  textarea: {},
  prefixPhoto: {},
  number: {
    align: "right",
  },
  checkbox: {
    width: 45,
    minWidth: 45,
    realWidth: 45,
  },
  date: {
    width: 130,
    minWidth: 130,
    realWidth: 130,
  },
  month: {
    width: 130,
    minWidth: 130,
    realWidth: 130,
  },
  year: {
    width: 130,
    minWidth: 130,
    realWidth: 130,
  },
  select: {
    width: 130,
    minWidth: 130,
    realWidth: 130,
  },
};

const forced = {
  draftLink: {
    renderCell: function (h, { row, column, store, $index }) {
      const { link, text, hasDraftId, customClass } = column.draftLinkOptions({
        row,
        column,
        store,
        $index,
      });
      return (
        (hasDraftId && (
          <a href={link} target="_blank" class={customClass}>
            {text}
          </a>
        )) ||
        (text && { text })
      );
    },
  },
  text: {
    renderCell: function (h, { row, column, store }) {
      const onInputExit = (ev) => {
        this.setCurrent(null, null);
        store.commit("updateData", row, {
          [column.property]: ev.target.value.trim(),
        });
      };

      return (
        <el-input
          ref="edit-component"
          onBlur={onInputExit}
          nativeOnChange={onInputExit}
          nativeOnKeyup={(ev) => ev.keyCode === 13 && onInputExit(ev)}
          value={row[column.property]}
        />
      );
    },
  },
  link: {
    renderCell: function (h, { row, column, store, $index }) {
      const linkOptions = column.linkOptions({ row, column, store, $index });
      return (
        row[column.property] && (
          <tab-link
            name={linkOptions.name}
            params={linkOptions.params}
            path={linkOptions.path}
            forceStatic={linkOptions.forceStatic}
          >
            {" "}
            {row[column.property]}{" "}
          </tab-link>
        )
      );
    },
  },
  textarea: {
    renderCell: function (h, { row, column, store }) {
      const onInputExit = (ev) => {
        this.setCurrent(null, null);
        store.commit("updateData", row, {
          [column.property]: ev.target.value.trim(),
        });
      };

      return (
        <el-input
          type="textarea"
          ref="edit-component"
          onBlur={onInputExit}
          nativeOnChange={onInputExit}
          // nativeOnKeyup={ev => ev.keyCode === 13 && onInputExit(ev)}
          value={row[column.property]}
        />
      );
    },
  },
  number: {
    renderCell: function (h, { row, column, store }) {
      const onInputExit = (ev) => {
        this.setCurrent(null, null);
        store.commit("updateData", row, {
          [column.property]: ev.target.value.trim(),
        });
      };
      const onInputChange = (ev) => {
        ev.target.value = ev.target.value.replace(/[^.0-9-]/g, "");
        if (ev.keyCode === 13) {
          onInputExit(ev);
        }
      };

      return (
        <el-input
          ref="edit-component"
          onBlur={onInputExit}
          nativeOnChange={onInputChange}
          nativeOnKeyup={onInputChange}
          value={row[column.property]}
        />
      );
    },
  },
  date: {
    renderCell: function (h, { row, column, store }) {
      const onInputExit = (value) => {
        store.commit("updateData", row, {
          [column.property]: value,
        });
      };

      let pickerOptions = datePicker.pickerOptions;
      if (column.type === "month") {
        pickerOptions = monthPicker.pickerOptions;
        column.dateValueFormat = DATE_FORMAT["longMonthValue2"];
      } else if (column.type === "year") {
        pickerOptions = yearPicker.pickerOptions;
      }

      objectAssign(pickerOptions, column.pickerOptions || {});
      /**
       * @desc
       */

      if (
        typeof column.customDisabled === "function" &&
        column.customDisabled(row)
      ) {
        return <span> {this.$util.dateFormat(row[column.property])}</span>;
      }

      return (
        <el-date-picker
          type={column.type}
          ref="edit-component"
          onInput={onInputExit}
          format={column.dateFormat}
          valueFormat={column.dateValueFormat}
          clearable={false}
          picker-options={pickerOptions}
          // eslint-disable-next-line no-unused-vars
          onBlur={(_componentInstance) => this.setCurrent(null, null)}
          value={row[column.property]}
        />
      );
    },
  },
  checkbox: {
    renderCell: function (h, { row, column, store }) {
      const onInputExit = (v) => {
        store.commit("updateData", row, {
          [column.property]: v,
        });
      };
      if (
        typeof column.customDisabled === "function" &&
        column.customDisabled(row)
      ) {
        return (
          <el-checkbox
            readOnly="true"
            trueLabel={column.trueLabel}
            falseLabel={column.falseLabel}
            value={row[column.property]}
          />
        );
      }
      // jsx
      return (
        <el-checkbox
          class={column.disabled ? "is-readonly" : ""}
          disabled={column.disabled}
          readOnly={column.readOnly}
          nativeOn-click={(event) => event.stopPropagation()}
          trueLabel={column.trueLabel}
          falseLabel={column.falseLabel}
          value={row[column.property]}
          on-input={onInputExit}
        />
      );
    },
  },
  select: {
    renderCell: function (h, { row, column, store }) {
      const onInputExit = (value) => {
        store.commit("updateData", row, {
          [column.property]: value,
        });

        //displayProperty가 있을 경우 이름값도 같이 data에 매핑함.
        if (column.displayProperty) {
          let label = "";
          const selectedItem = this.selectboxItems.find((list) => {
            return list.value === getPropByPath(row, column.property).v;
          });
          if (selectedItem) {
            label = selectedItem.label;
          }
          store.commit("updateData", row, {
            [column.displayProperty]: label,
          });
        }
      };

      if (
        typeof column.customDisabled === "function" &&
        column.customDisabled(row)
      ) {
        return <span>{row[column.property + "Name"]}</span>;
      }

      const valueKey = this.valueKey;
      return (
        <el-select
          size="small"
          v-focus
          style="width: 100%"
          ref="edit-component"
          onInput={onInputExit}
          clearable={!column.required}
          value={row[column.property]}
        >
          {this.selectboxItems &&
            this.selectboxItems.map((item) => (
              <el-option
                label={item.label}
                key={item.value}
                value={item[valueKey]}
              />
            ))}
        </el-select>
      );
    },
  },
  deletion: {
    renderHeader: function (h, { store, table }) {
      let { data } = store.states;
      data = data.filter((e) => !e.deleteDisabled);
      const isAllDeletionSelected = data.every((row) => row.checkboxDelete);
      const deletions = data.some((row) => row.checkboxDelete);
      const handleChange = debounce(10, function (condition) {
        replaceArray(store.states.data, (row) => {
          if (condition && row.manipulationType === "C") {
            return null;
          }

          if (!row.deleteDisabled) {
            store.commit("updateData", row, {
              checkboxDelete: condition,
            });
          }

          return row;
        });
        store.commit("updateAllDeletionCheckState", condition);
        // re-render를 하지않아..  re render trigger.
        table.forceRender();
      });

      return (
        <el-checkbox
          disabled={data && data.length === 0}
          indeterminate={deletions && !isAllDeletionSelected}
          nativeOn-click={() => handleChange(!isAllDeletionSelected)}
          value={isAllDeletionSelected}
        >
          {table.t("el.table.removeText")}
        </el-checkbox>
      );
    },
    renderCell: function (h, { row, store }) {
      const handleChange = (value, row) => {
        if (value && (row.manipulationType === "C" || !row.rnum)) {
          store.commit("removeRow", row);
          return;
        }

        if (value && row.manipulationType === "U" && row.xlsx) {
          store.commit("removeRow", row);
          return;
        }

        store.commit(
          "updateData",
          row,
          { checkboxDelete: value },
          { updateCurrent: false },
        );
      };
      //삭제 체크박스 있으면
      let showYn = false;
      if (row.deleteDisabled) {
        showYn = row.deleteDisabled;
      }

      let checkbox = (
        <el-checkbox
          disabled={showYn}
          nativeOn-click={(event) => event.stopPropagation()}
          value={row.checkboxDelete}
          on-input={(value) => handleChange(value, row)}
        />
      );

      return checkbox;
    },
    sortable: false,
    resizable: false,
  },
  selection: {
    renderHeader: function (h, { store, table }) {
      const self = this;
      let { data } = store.states;

      // FIXME: programCode 하드코딩제거예정 (Hanjoong.Cho)
      // data 내부속성에 selectDisabled 속성을 넘겨받아서 처리가능한 구조로 변경
      if (table.programCode === "HAS1010") {
        data = data.filter((d) => d.chkYn === "N");
      } else {
        data = data.filter((d) => !d.selectDisabled);
      }

      const isAllSelected = data.every((row) => row.checkboxSelect); // 배열의 모든 값이 조건을 만족해야 true 반환
      const selections = data.some((row) => row.checkboxSelect); // 배열의 일부 값이 조건을 만족하면 true 반환
      const handleChange = debounce(10, function (condition) {
        let index = 0;
        while (data[index]) {
          store.commit("updateData", data[index], {
            checkboxSelect: condition,
          });
          index++;
        }
        self.toggleAllSelection();
      });

      return (
        <el-checkbox
          disabled={data && data.length === 0}
          indeterminate={selections && !isAllSelected}
          nativeOn-click={() => handleChange(!isAllSelected)}
          value={isAllSelected}
        >
          {table.t("el.table.selectText")}
        </el-checkbox>
      );
    },
    renderCell: function (h, { row, column, store, $index }) {
      const handleChange = (row) => {
        store.commit("updateData", row, {
          checkboxSelect: !row.checkboxSelect,
        });
        store.commit("rowSelectedChanged", row);
      };

      // 기존로직 적용
      let showYn = column.selectable
        ? !column.selectable.call(null, row, $index)
        : false;

      // [2022.12.28] chkYn값이 N이면 disable
      // FIXME: programCode 하드코딩제거예정 (Hanjoong.Cho)
      // selectable 함수를 넘겨받아서 disabled여부 체크되도록 변경
      // selectDisabled 속성을 사용해도 될 것 같으나 기존로직에서 selectable 함수를 이용하여 체크하는 부분이 존재하기 때문에 selectable함수를 이용하도록 구현예정
      if (store?.table?.programCode === "HAS1010" && row.deleteDisabled) {
        showYn = row.deleteDisabled;
      }
      return (
        <el-checkbox
          nativeOn-click={(event) => event.stopPropagation()}
          value={row.checkboxSelect}
          disabled={showYn}
          on-input={() => handleChange(row)}
        />
      );
    },
    sortable: false,
    resizable: false,
  },
  index: {
    renderHeader: function (h, { column }) {
      return column.label || "#";
    },
    renderCell: function (h, { $index, column }) {
      let i = $index + 1;
      const index = column.index;

      if (typeof index === "number") {
        i = $index + index;
      } else if (typeof index === "function") {
        i = index($index);
      }

      return <div>{i}</div>;
    },
    sortable: false,
  },
  expand: {
    renderHeader: function (h, { column }) {
      return column.label || "";
    },
    renderCell: function (h, { row, store }, proxy) {
      const expanded = store.states.expandRows.indexOf(row) > -1;
      return (
        <div
          class={
            "el-table__expand-icon " +
            (expanded ? "el-table__expand-icon--expanded" : "")
          }
          on-click={(e) => proxy.handleExpandClick(row, e)}
        >
          <i class="el-icon el-icon-arrow-right" />
        </div>
      );
    },
    sortable: false,
    resizable: false,
    className: "el-table__expand-column",
  },
};

const extras = {
  // prefix 전용 jsx
  photo: {
    renderPrefix: function (h, { row, column }) {
      const prefixPhotoOptions = column.prefixPhoto({
        column: column,
        row: row,
      });
      return (
        <photo-icon
          src={prefixPhotoOptions.src}
          fallbackSrc={prefixPhotoOptions.fallbackSrc}
          width={prefixPhotoOptions.width}
          height={prefixPhotoOptions.height}
        ></photo-icon>
      );
    },
  },
};

const getDefaultColumn = function (type, options) {
  const column = {};
  objectAssign(column, defaults[type || "default"]);

  for (let name in options) {
    // eslint-disable-next-line no-prototype-builtins
    if (options.hasOwnProperty(name)) {
      const value = options[name];
      if (typeof value !== "undefined") {
        column[name] = value;
      }
    }
  }

  if (!column.minWidth) {
    column.minWidth = 80;
  }

  column.realWidth =
    column.width === undefined ? column.minWidth : column.width;

  if (column.prefixPhoto)
    column.width = column.realWidth = column.realWidth + 30; // prefix photo 가 있는 경우 20 픽셀 추가

  return column;
};

const DEFAULT_RENDER_CELL = function (h, { row, column, $index }) {
  const property = column.property;
  const value = property && getPropByPath(row, property).v;
  if (column && column.formatter) {
    return column.formatter(row, column, value, $index);
  }
  return value;
};

const parseWidth = (width) => {
  if (width !== undefined) {
    width = parseInt(width, 10);
    if (isNaN(width)) {
      width = null;
    }
  }
  return width;
};

const parseMinWidth = (minWidth) => {
  if (minWidth !== undefined) {
    minWidth = parseInt(minWidth, 10);
    if (isNaN(minWidth)) {
      minWidth = 80;
    }
  }
  return minWidth;
};

export default {
  name: "ElTableColumnExtend",

  props: {
    customDisabled: [Function, Boolean],
    renderCell: Function,
    type: {
      type: String,
      default: "default",
    },
    code: String, // 공통코드
    fetchMethod: Function, // selectbox fetch
    linkOptions: [Function, Object], // linkOption
    draftLinkOptions: [Function, Object], // draftLinkOption
    prefixPhoto: null, // prefixPhoto
    labelKey: String,
    valueKey: {
      type: String,
      default: "lookupValueId",
    },
    dateFormat: {
      type: String,
      default: DATE_FORMAT["longDate2"],
    },
    dateValueFormat: {
      type: String,
      default: DATE_FORMAT["longDateValue2"],
    },
    trueLabel: [String, Number],
    falseLabel: [String, Number],
    readOnly: Boolean,
    required: Boolean,
    useTemplate: {
      type: Boolean,
      default: false,
    },
    editIconFlag: {
      type: Boolean,
      default: false,
    },
    // ex; select option list
    inputItems: {
      type: Array,
      default() {
        return [];
      },
    },

    label: String,
    className: String,
    labelClassName: String,
    property: String,
    prop: String,
    displayProp: String,
    width: {},
    minWidth: {},
    renderHeader: Function,
    sortable: {
      type: [String, Boolean],
      default: false,
    },
    sortMethod: Function,
    sortBy: [String, Function, Array],
    resizable: {
      type: Boolean,
      default: true,
    },
    context: {},
    columnKey: String,
    align: String,
    headerAlign: {
      type: String,
      default: "center",
    },
    summaryAlign: String,
    showTooltipWhenOverflow: Boolean,
    showOverflowTooltip: Boolean,
    fixed: [Boolean, String],
    formatter: Function,
    validator: Function,
    onInvalid: Function, // column validator에서 유효성 검증에 통과하지 못할경우 콜백 핸들러
    selectable: Function,
    reserveSelection: Boolean,
    filterMethod: Function,
    filteredValue: Array,
    filters: Array,
    filterPlacement: String,
    filterMultiple: {
      type: Boolean,
      default: true,
    },
    index: [Number, Function],
    sortOrders: {
      type: Array,
      default() {
        return ["ascending", "descending", null];
      },
      validator(val) {
        return val.every(
          (order) => ["ascending", "descending", null].indexOf(order) > -1,
        );
      },
    },
    focusWhenRowAdded: Boolean,
  },

  data() {
    return {
      isSubColumn: false,
      columns: [],
      inputRemoteItems: [],
    };
  },

  beforeCreate() {
    this.row = {};
    this.column = {};
    this.$index = 0;
  },

  components: {},

  computed: {
    selectboxItems() {
      let items = [];
      if (this.inputItems.length) {
        items = items.concat(this.inputItems);
      }
      if (this.inputRemoteItems.length) {
        items = items.concat(this.inputRemoteItems);
      }
      return items;
    },
    owner() {
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      return parent;
    },
    states() {
      return this.owner.store.states;
    },
    commit() {
      return this.owner.store.commit;
    },
    columnOrTableParent() {
      let parent = this.$parent;
      while (parent && !parent.tableId && !parent.columnId) {
        parent = parent.$parent;
      }
      return parent;
    },
  },

  methods: {
    getInputType(type) {
      const dateTypes = ["date", "month", "year"];
      if (dateTypes.indexOf(type) !== -1) {
        this.dateType = type;
        return "date";
      }
      return type;
    },
    setCurrent(column, row) {
      this.owner.store.commit("setCurrentCell", column, row);
    },
    /**
     * @desc fetch selectbox list
     * fetch by one of: code / fetchMethod
     */
    async fetchSelect({ code, fetchMethod, inputItems }) {
      if (code) {
        const response = await CommonAPI.getCodes({
          lookupType: code,
        });
        this.inputRemoteItems = response.data.payload.map((item) => {
          item.label = item.meaning;
          item.value = item[this.valueKey];
          return item;
        });
      } else {
        if (!inputItems) {
          const response = await fetchMethod();
          this.inputRemoteItems = response?.data?.payload || [];
        } else {
          this.inputRemoteItems = this.inputItems;
        }
      }
    },
  },

  created() {
    this.customRender = this.$options.render;
    this.$options.render = (h) => h("div", this.$slots.default);

    let parent = this.columnOrTableParent;
    let owner = this.owner;
    let self = this;
    this.isSubColumn = owner !== parent;
    this.columnId =
      (parent.tableId || parent.columnId) + "_column_" + columnIdSeed++;

    let type = this.type;

    const width = parseWidth(this.width);
    const minWidth = parseMinWidth(this.minWidth);

    let isColumnGroup = false;
    let column = getDefaultColumn.call(this, type, {
      id: this.columnId,
      columnKey: this.columnKey,
      label: this.label,
      className: this.className,
      labelClassName: this.labelClassName,
      property: this.prop || this.property,
      displayProperty: this.displayProp,
      type,
      renderCell: this.renderCell || null,
      renderHeader: this.renderHeader,
      minWidth,
      width,
      isColumnGroup,
      context: this.context,
      align: this.align ? "is-" + this.align : null,
      headerAlign: this.headerAlign
        ? "is-" + this.headerAlign
        : this.align
        ? "is-" + this.align
        : null,
      summaryAlign: this.summaryAlign
        ? "is-" + this.summaryAlign
        : this.align
        ? "is-" + this.align
        : null,
      sortable: this.sortable === "" ? true : this.sortable,
      sortMethod: this.sortMethod,
      sortBy: this.sortBy,
      resizable: this.resizable,
      showOverflowTooltip:
        this.showOverflowTooltip || this.showTooltipWhenOverflow,
      formatter: this.formatter,
      selectable: this.selectable,
      reserveSelection: this.reserveSelection,
      fixed: this.fixed === "" ? true : this.fixed,
      filterMethod: this.filterMethod,
      filters: this.filters,
      filterable: (this.filters && this.filters.length) || this.filterMethod,
      filterMultiple: this.filterMultiple,
      filterOpened: false,
      filteredValue: this.filteredValue || [],
      filterPlacement: this.filterPlacement || "",
      index: this.index,
      sortOrders: this.sortOrders,
      dateFormat: this.dateFormat,
      dateValueFormat: this.dateValueFormat,
      trueLabel: this.trueLabel,
      falseLabel: this.falseLabel,
      readOnly: this.readOnly,
      required: this.required,
      validator: this.validator,
      onInvalid: this.onInvalid,
      useTemplate: this.useTemplate,
      editIconFlag: this.editIconFlag,
      inputItems: this.inputItems,
      linkOptions: this.linkOptions, // tab-link 옵션
      draftLinkOptions: this.draftLinkOptions, // draft 문서 링크 옵션
      prefixPhoto: this.prefixPhoto, // 이미지 Source
      code: this.code,
      fetchMethod: this.fetchMethod,
      focusWhenRowAdded: this.focusWhenRowAdded,
      disabled: this.readOnly
        ? true
        : owner.programCode && !eventPermissionCheck("U", owner.programCode),
      customDisabled: this.customDisabled,
      valueKey: this.valueKey,
    });

    let source = forced[this.getInputType(type)] || {};
    for (let prop in source) {
      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(prop)) {
        let value = source[prop];
        if (value !== undefined) {
          column[prop] =
            prop === "className" ? `${column[prop]} ${value}` : value;
        }
      }
    }

    /**
     * custom renderCell 함수가 있는 경우
     */
    if (this.renderCell && this.renderCell.constructor == Function) {
      column.renderCell = this.renderCell;
    }

    this.columnConfig = column;
    let renderCell = null;
    if (this.owner.isEditableColumn(column)) {
      renderCell = column.renderCell;
      if (typeof renderCell === "function") {
        renderCell = renderCell.bind(this);
      }

      if (type === "checkbox") {
        column.renderCell = function (h, data) {
          return (
            <div class="cell">{renderCell(h, data, this._renderProxy)}</div>
          );
        };
        return;
      }

      const handleOuterFocus = (e, { row, column }) => {
        self.setCurrent(column, row);
      };

      column.renderCell = function (h, data) {
        let value =
          column.property && getPropByPath(data.row, column.property).v;

        // selectbox value mapping
        if (column && column.type === "select") {
          const selectedItem = self.selectboxItems.find((list) => {
            return list.value === getPropByPath(data.row, column.property).v;
          });
          if (selectedItem) {
            value = selectedItem.label;
          }
        }

        if (column && column.formatter) {
          value = column.formatter(data.row, column, value, data.$index);
        }

        if (
          self.states.current.row === data.row &&
          self.states.current.column === data.column
        ) {
          if (renderCell) {
            const renderValue = renderCell.call(self, h, data);
            Vue.nextTick(() => {
              if (renderValue.context.$refs["edit-component"]) {
                renderValue.context.$refs["edit-component"].focus();
              }
            });
            return <div class="cell">{renderValue}</div>;
          }
        }

        let content = <div domPropsInnerHTML="&nbsp;" />;

        if (self.$slots.default && column.useTemplate) {
          content = self.$slots.default;
        } else if (self.$scopedSlots.default && column.useTemplate) {
          // 사용자 정의 template 컬럼 render
          data.current =
            self.states.current.row === data.row &&
            self.states.current.column === data.column;
          data.update = function (value) {
            owner.store.commit("updateData", data.row, {
              [column.property]: value,
            });
            self.setCurrent(null, null);
          };
          data.set = function (property, value) {
            owner.store.commit("updateData", data.row, {
              [property]: value,
            });
            self.setCurrent(null, null);
          };
          content = self.$scopedSlots.default(data);
        } else {
          content = value;
        }

        return column.showOverflowTooltip ? (
          <div
            class="cell el-tooltip"
            tabindex={data.current ? -1 : 0}
            style={{
              outline: "0",
              width: (column.realWidth || column.width) - 1 + "px",
            }}
            onFocus={(e) => handleOuterFocus(e, data)}
          >
            {content || value}
          </div>
        ) : (
          <div
            class="cell"
            tabindex={data.current ? -1 : 0}
            style="outline: 0;"
            onFocus={(e) => handleOuterFocus(e, data)}
          >
            {content || value}
          </div>
        );
      };

      return;
    }

    if (
      [
        "deletion",
        "selection",
        "checkbox",
        "index",
        "link",
        "draftLink",
      ].includes(type)
    ) {
      renderCell = column.renderCell.bind(this);
    }

    column.renderCell = function (h, data) {
      if (!renderCell) {
        renderCell = DEFAULT_RENDER_CELL;
      }

      let content = null;
      let prefix = null;

      if (column.useTemplate) {
        // use template
        if (self.$slots.default) {
          content = self.$slots.default;
        } else if (self.$scopedSlots.default) {
          data.current = false;
          data.update = function () {};
          content = self.$scopedSlots.default(data);
        }
      } else if (type === "select") {
        // selectbox value mapping
        let value = getPropByPath(data.row, column.property).v;
        const selected = self.selectboxItems.find(
          (list) => list.value === value,
        );
        if (selected) {
          value = selected.label;
        }
        content = value;
      } else {
        // 그외
        content = renderCell.call(self, h, data);
      }

      if (column.prefixPhoto) {
        // prefix 경우의 수가 늘어날 경우 수정 바람
        prefix = extras.photo.renderPrefix.call(self, h, data);
      }

      return self.showOverflowTooltip || self.showTooltipWhenOverflow ? (
        <div
          class="cell el-tooltip"
          style={{
            width: (data.column.realWidth || data.column.width) - 1 + "px",
          }}
        >
          {column.prefixPhoto ? prefix : ""}
          {content}
        </div>
      ) : (
        <div class="cell">
          {column.prefixPhoto ? prefix : ""}
          {content}
        </div>
      );
    };
  },

  destroyed() {
    if (!this.$parent) return;
    const parent = this.$parent;
    this.owner.store.commit(
      "removeColumn",
      this.columnConfig,
      this.isSubColumn ? parent.columnConfig : null,
    );
  },

  watch: {
    inputItems(newVal) {
      if (this.columnConfig) {
        this.columnConfig.inputItems = newVal;
      }
    },

    label(newVal) {
      if (this.columnConfig) {
        this.columnConfig.label = newVal;
      }
    },

    prop(newVal) {
      if (this.columnConfig) {
        this.columnConfig.property = newVal;
      }
    },

    property(newVal) {
      if (this.columnConfig) {
        this.columnConfig.property = newVal;
      }
    },

    filters(newVal) {
      if (this.columnConfig) {
        this.columnConfig.filters = newVal;
      }
    },

    filterMultiple(newVal) {
      if (this.columnConfig) {
        this.columnConfig.filterMultiple = newVal;
      }
    },

    align(newVal) {
      if (this.columnConfig) {
        this.columnConfig.align = newVal ? "is-" + newVal : null;

        if (!this.headerAlign) {
          this.columnConfig.headerAlign = newVal ? "is-" + newVal : null;
        }
      }
    },

    headerAlign(newVal) {
      if (this.columnConfig) {
        this.columnConfig.headerAlign = "is-" + (newVal || this.align);
      }
    },

    summaryAlign(newVal) {
      if (this.columnConfig) {
        this.columnConfig.summaryAlign = "is-" + (newVal || this.align);
      }
    },

    width(newVal) {
      if (this.columnConfig) {
        this.columnConfig.width = parseWidth(newVal);
        this.owner.store.scheduleLayout();
      }
    },

    minWidth(newVal) {
      if (this.columnConfig) {
        this.columnConfig.minWidth = parseMinWidth(newVal);
        this.owner.store.scheduleLayout();
      }
    },

    fixed(newVal) {
      if (this.columnConfig) {
        this.columnConfig.fixed = newVal;
        this.owner.store.scheduleLayout(true);
      }
    },

    sortable(newVal) {
      if (this.columnConfig) {
        this.columnConfig.sortable = newVal;
      }
    },

    index(newVal) {
      if (this.columnConfig) {
        this.columnConfig.index = newVal;
      }
    },

    formatter(newVal) {
      if (this.columnConfig) {
        this.columnConfig.formatter = newVal;
      }
    },

    className(newVal) {
      if (this.columnConfig) {
        this.columnConfig.className = newVal;
      }
    },

    labelClassName(newVal) {
      if (this.columnConfig) {
        this.columnConfig.labelClassName = newVal;
      }
    },
  },

  mounted() {
    const { type, code, fetchMethod } = this;
    if (type === "select" && (code || fetchMethod)) {
      this.fetchSelect({ code, fetchMethod });
    }
    const owner = this.owner;
    const parent = this.columnOrTableParent;
    let columnIndex;

    if (!this.isSubColumn) {
      columnIndex = [].indexOf.call(
        parent.$refs.hiddenColumns.children,
        this.$el,
      );
    } else {
      columnIndex = [].indexOf.call(parent.$el.children, this.$el);
    }

    // 삭제 이벤트권한 체크
    if (
      this.columnConfig.type === "deletion" &&
      owner.programCode &&
      eventPermissionCheck("D", owner.programCode) === false
    ) {
      return;
    }

    owner.store.commit(
      "insertColumn",
      this.columnConfig,
      columnIndex,
      this.isSubColumn ? parent.columnConfig : null,
    );
  },
};
