<template>
  <div id="section-to-print" class="print-certificate">
    <div class="title-area">
      <div class="print-button hide-when-print">
        <el-checkbox
          v-if="printCertiData.retireReasonCodeName"
          v-model="ignoreRetireReasonCodeName"
        >
          <span>{{ lang("버튼.퇴직사유제외") }}</span>
        </el-checkbox>
        <el-button :disabled="pending" size="small" @click="handlePrint">
          <span>{{ lang("버튼.프린트_하기") }}</span>
        </el-button>
      </div>
    </div>

    <div v-if="pending" class="print-page" />

    <div v-else id="print" class="print-page">
      <img
        v-if="watermarkImg"
        :src="watermarkImg"
        alt=""
        class="watermark-img"
        @error="hideImage($event)"
      />
      <div class="print-sector">
        <p class="print-title">{{ printCertiData.certificateTypeName }}</p>

        <div class="docu-number">
          <span>
            {{
              lang("발급번호", null, locale, {
                printCode: printCertiData.printIssueCode,
              })
            }}
          </span>
        </div>
        <div class="employee-info">
          <h3>{{ lang("타이틀.인적사항", null, locale) }}</h3>
          <table class="table-box">
            <tr>
              <th>{{ lang("테이블.헤더.성명", null, locale) }}</th>
              <td>{{ printCertiData.employeeName }}</td>
              <th>{{ lang("테이블.헤더.생년월일", null, locale) }}</th>
              <td>{{ dateFormat(printCertiData.birthYmd) }}</td>
            </tr>
            <tr>
              <th>{{ lang("테이블.헤더.주소", null, locale) }}</th>
              <td colspan="3">
                {{ printCertiData.printAddress }}
              </td>
            </tr>
          </table>
        </div>
        <div class="company-info">
          <h3>{{ lang("타이틀.재직사항", null, locale) }}</h3>
          <table class="table-box">
            <tr>
              <th>{{ lang("테이블.헤더.회사명", null, locale) }}</th>
              <td>
                {{ printCertiData.companyName }}
              </td>
              <th>{{ lang("테이블.헤더.사업자등록번호", null, locale) }}</th>
              <td>
                {{ printCertiData.companyNumber }}
              </td>
            </tr>
            <tr>
              <th>{{ lang("테이블.헤더.회사주소", null, locale) }}</th>
              <td colspan="3">
                {{ printCertiData.companyAddress }}
              </td>
            </tr>
            <tr>
              <th>{{ lang("테이블.헤더.최종근무부서", null, locale) }}</th>
              <td>
                {{ printCertiData.organizationName }}
              </td>
              <th>{{ lang("테이블.헤더.최종직책", null, locale) }}</th>
              <td>
                {{ printCertiData.dutyName }}
              </td>
            </tr>
            <tr>
              <th>{{ lang("테이블.헤더.재직기간", null, locale) }}</th>
              <td colspan="3">
                {{ dateFormat(printCertiData.hireYmd) }} ~
                {{ retireDateFormat(printCertiData.retireYmd) }}
                <template v-if="printCertiData.printGubun !== '재직'">
                  ({{
                    totalDay(printCertiData.hireYmd, printCertiData.retireYmd)
                  }})
                </template>
              </td>
            </tr>
            <tr
              v-if="
                printCertiData.retireReasonCodeName &&
                !ignoreRetireReasonCodeName
              "
            >
              <th>{{ lang("테이블.헤더.퇴직사유", null, locale) }}</th>
              <td colspan="3">
                {{ printCertiData.retireReasonCodeName }}
              </td>
            </tr>
          </table>
        </div>
        <div class="print-reason">
          <h3>{{ lang("타이틀.발급사유", null, locale) }}</h3>
          <table class="table-box">
            <tr>
              <th>{{ lang("테이블.헤더.발급용도", null, locale) }}</th>
              <td>
                {{ printCertiData.requestPurposeName }}
              </td>
              <th>{{ lang("테이블.헤더.제출처", null, locale) }}</th>
              <td>
                {{ printCertiData.submitPlace }}
              </td>
            </tr>
            <tr>
              <th>{{ lang("테이블.헤더.비고", null, locale) }}</th>
              <td colspan="3">
                {{ printCertiData.note }}
              </td>
            </tr>
          </table>
        </div>
        <p class="print-footer">
          {{ lang(`출력물문구_${printCertiData.printGubun}`, null, locale) }}
        </p>
        <p class="print-date">{{ dateFormat(printCertiData.wishYmd) }}</p>
        <div class="table-footer" cellspacing="0">
          <ul>
            <li class="company-name" :class="{ 'is-korean': isKorean }">
              {{ printCertiData.companyName }}
            </li>
            <li class="company-president" :class="{ 'is-korean': isKorean }">
              {{ companyPresident }}

              <img
                v-if="sealImg"
                :src="sealImg"
                alt="Company Seal"
                class="seal-img"
                @error="hideImage($event)"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { i18nTranslator } from "@/mixins/i18n";
import helpers from "@/auth/helpers";
import { apiBaseUrl } from "@/config/cloudEnv";
import _ from "lodash";

export default {
  name: "PrintCertificate",

  mixins: [i18nTranslator("PrintCertificate")],

  props: {
    certificateId: {
      type: Number,
      default: 0,
    },
    wishYmd: {
      type: String,
      default: null,
    },
    originPrintCertiData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      hasData: false,
      pending: true,
      watermarkImg: null,
      sealImg: null,
      fileList: [],
      requestPurpose: null,
      printCertiData: {},
      ignoreRetireReasonCodeName: false,
    };
  },

  computed: {
    isKorean() {
      return this.locale === "ko";
    },
    locale() {
      return this.printCertiData.locale?.toLowerCase();
    },
    companyPresident() {
      const { companyPresidentDuty, companyPresident } = this.printCertiData;
      return `${companyPresidentDuty} ${companyPresident}`;
    },
  },

  async mounted() {
    try {
      this.pending = true;
      await Promise.all([this.getPrintData(), this.getImages()]);
    } finally {
      this.pending = false;
    }
  },

  methods: {
    hideImage({ target }) {
      target.style.display = "none";
    },
    getPrintData() {
      this.printCertiData = _.cloneDeep(this.originPrintCertiData);

      for (let data in this.printCertiData) {
        if (
          (this.printCertiData[data] === null ||
            this.printCertiData[data] === "") &&
          data !== "retireReasonCodeName"
        ) {
          this.printCertiData[data] = "-";
        }
      }

      let companyRigNum = this.printCertiData.companyNumber;
      if (companyRigNum !== "-") {
        let numberInput1 = companyRigNum.substr(0, 3);
        let numberInput2 = companyRigNum.substr(3, 2);
        let numberInput3 = companyRigNum.substr(5, 5);
        this.printCertiData.companyNumber = `${numberInput1}-${numberInput2}-${numberInput3}`;
      }
    },

    async getImages() {
      const {
        data: { payload: list },
      } = await helpers.get(`/hpe/sealandwatermark/images`, {
        companyId: this.printCertiData.requestCompanyOriginalId,
        wishYmd: this.wishYmd,
      });
      this.fileList = this.mapFileList(list);
      this.fileList.forEach((el) => {
        if (el.sealAndWatermarkType === 1) {
          this.sealImg = el.url;
        } else {
          this.watermarkImg = el.url;
        }
      });
    },

    mapFileList(files = []) {
      return files.map((f) => {
        return {
          name: f.originalFileName,
          url: `${apiBaseUrl()}/attachment/groups/${f.attachGroupId}/files/${
            f.attachValueId
          }/download`,
          sealAndWatermarkType: f.sealAndWatermarkType,
        };
      });
    },

    async handlePrint() {
      if (this.pending) {
        return;
      }
      await helpers.put(`/proof/apply/count/${this.certificateId}`);

      const html = document.querySelector("html");
      const printContents =
        document.querySelector(".print-certificate").innerHTML;
      const printDiv = document.createElement("DIV");

      printDiv.className = "print-certificate";
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";

      const watermarkImg = printDiv.querySelector(".watermark-img");
      const sealImg = printDiv.querySelector(".seal-img");

      let count = 0;
      let watermarkImageError = true;
      let sealImageError = true;

      const printPage = () => {
        if (count < 2) {
          return;
        }
        if (watermarkImageError === false) {
          watermarkImg.style.display = "none";
        }
        if (sealImageError === false) {
          sealImg.style.display = "none";
        }

        window.print();
        document.body.style.display = "block";
        printDiv.style.display = "none";
      };

      const proc = (type) => {
        count += 1;
        if (type === "watermark") {
          watermarkImageError = false;
        }
        if (type === "seal") {
          sealImageError = false;
        }
        printPage();
      };

      if (watermarkImg) {
        watermarkImg.onload = () => {
          proc(null);
        };
        watermarkImg.onerror = () => {
          proc("watermark");
        };
      } else {
        proc(null);
      }

      if (sealImg) {
        sealImg.onload = () => {
          proc(null);
        };
        sealImg.onerror = () => {
          proc("seal");
        };
      } else {
        proc(null);
      }
    },
    dateFormat(date) {
      if (date === "-") {
        return date;
      }

      const dateFormatEn = "MMMM Do[,] YYYY";
      const dateFormatKo = `YYYY[년] M[월] D[일]`;
      const formatString = this.locale === "ko" ? dateFormatKo : dateFormatEn;

      return moment(date).format(formatString);
    },
    retireDateFormat(date) {
      if (date === "-") {
        return this.$t("PrintCertificate.테이블.현재까지", this.locale);
      } else {
        return this.dateFormat(date);
      }
    },
    totalDay(hireYmd, retireYmd) {
      let start, end;
      start = new Date(hireYmd);
      if (retireYmd === "-") {
        end = new Date();
      } else {
        end = new Date(retireYmd);
      }

      end.setDate(end.getDate() + 1);

      // 두 날짜 사이의 년, 월, 일 계산
      let years = end.getFullYear() - start.getFullYear();
      let months = end.getMonth() - start.getMonth();
      let days = end.getDate() - start.getDate();

      // 일이 음수인 경우 처리
      if (days < 0) {
        months--;
        days += new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      }

      // 월이 음수인 경우 처리
      if (months < 0) {
        years--;
        months += 12;
      }
      let result = "";
      if (years !== 0) {
        result +=
          years + this.$t("PrintCertificate.테이블.몇년", this.locale) + " ";
      }

      if (months !== 0) {
        result +=
          months + this.$t("PrintCertificate.테이블.개월", this.locale) + " ";
      }

      if (days !== 0) {
        result += days + this.$t("PrintCertificate.테이블.일", this.locale);
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.print-certificate {
  padding: 16px;
  margin: auto;

  .title-area {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: $--font-size-medium;
      font-weight: $gpro-font-weight-700;
    }

    .print-button {
      margin-left: auto;

      label {
        margin-right: 4px;
      }
    }
  }

  .docu-number {
    text-align: right;
    font-size: $--font-size-small;
    font-weight: $gpro-font-weight-700;
    margin: 25px 0;
    position: absolute;
    right: 0;
    top: -35px;
    width: 110px;
  }

  h3 {
    font-size: $--font-size-medium;
    font-weight: $gpro-font-weight-700;
    margin-bottom: 14px;
    margin-top: 55px;
  }

  .print-page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 0 auto;
    border-radius: 5px;
    background: $--color-white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    word-break: break-word;

    .watermark-img {
      margin: 0;
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      opacity: 0.35;
    }
  }

  .print-sector {
    position: relative;
    width: 650px;
    height: 257mm;
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: center center;

    .print-title {
      font-size: 29px;
      font-weight: $gpro-font-weight-700;
      margin-bottom: 85px;
    }
  }

  .table-box {
    font-size: $--font-size-small;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border-collapse: collapse;
    text-align: left;

    tr {
      background-color: transparent;
    }

    th {
      background: $--background-color-base;
      border: 1px solid $--border-color-light;
      padding: 8px 16px 8px 12px;
      width: 150px;
      height: 30px;
      font-weight: $gpro-font-weight-700;
    }

    td {
      padding: 9px 16px;
      border: 1px solid $--border-color-light;
      width: 270px;
      height: 30px;
    }
  }

  .seal-img {
    position: absolute;
    top: -22px;
    right: -24px;
    width: 60px;
    max-width: 60px;
    height: 60px;
    max-height: 60px;
    object-fit: contain;
    display: block;
    opacity: 0.65;
  }

  .print-footer {
    margin-top: 75px;
    font-size: $--font-size-base;
    font-weight: $gpro-font-weight-700;
  }

  .print-date {
    font-size: $--font-size-small;
    font-weight: $gpro-font-weight-700;
    margin-top: 15px;
  }

  .table-footer {
    font-size: $--font-size-base;
    position: relative;
    margin-top: 80px;
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      align-items: center;
    }

    .is-korean {
      font-size: $--font-size-extra-large;
      letter-spacing: 1rem;
      font-weight: $gpro-font-weight-700;

      .seal-img {
        top: -19px;
        right: -0.65rem;
      }
    }

    .company-president {
      position: relative;
      font-weight: $gpro-font-weight-700;

      &.is-korean {
        @extend .is-korean;
      }
    }

    .company-name {
      margin-right: 15px;
      font-weight: $gpro-font-weight-700;

      &.is-korean {
        @extend .is-korean;
      }
    }

    .company-stamp {
      position: absolute;
      right: 0;
      bottom: 8px;
    }
  }
}

@media print {
  * {
    z-index: 1;
  }
  .print-certificate {
    .title-area {
      display: none !important;
    }
  }
  .print-certificate .table-box th {
    background: $--background-color-base;
  }
}

@page {
  size: A4;
  margin: 0 !important;
}
</style>
